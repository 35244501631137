#title {
	color: white;
	font-weight: 600;
}
/* width */
::-webkit-scrollbar {
	width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgb(167, 167, 167);
	border-radius: 10px;
}
.grey{
	background-color: #343a40 !important;
}
.nav-link{
	background-color: #eeebe4  !important;
	color: black !important;
}
.nav-link:focus{
background-color: #007bff!important;
color: white !important;
}
body {
	overflow-x: hidden; /* Hide horizontal scrollbar */
  }

