@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Poppins:wght@400;600&display=swap);
body {
	margin: 0;
	font-family: "Poppins", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

#title {
	color: white;
	font-weight: 600;
}
/* width */
::-webkit-scrollbar {
	width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: rgb(167, 167, 167);
	border-radius: 10px;
}
.grey{
	background-color: #343a40 !important;
}
.nav-link{
	background-color: #eeebe4  !important;
	color: black !important;
}
.nav-link:focus{
background-color: #007bff!important;
color: white !important;
}
body {
	overflow-x: hidden; /* Hide horizontal scrollbar */
  }


.my_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    background-color: #e9ecef;
}

.my_p {
    font-size: 18px;
    margin-bottom: -20px;
}

.Heading {
    font-size: 2rem;
    padding-bottom: 10px;
}

.card_1 {
    background-color: white;
    align-items: center;
    width: 350px;
    padding: 20px;
}

.my_css {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 1rem;
}

.last_section {
    padding-top: 1 5px;
}

.small-box.bg-warning .inner {
    color: white;
}
.small-box.bg-warning a {
    color: white !important;
}
.col-lg-3 .small-box h3, .col-md-3 .small-box h3, .col-xl-3 .small-box h3 {
    font-size: 1.2rem;
}
.content-wrapper {
    margin-left: 0px !important;
}
.main-navbar {
	display: flex;
	min-height: 92vh;
	flex-direction: column;
	justify-content: space-between;
	background: aliceblue;
	
}
#title {
	color: white;
	font-weight: 600;
}

.profile {
	color: white !important;
	background: rgb(255, 35, 108) !important;
	width: 60px !important;
	height: 60px !important;
	font-size: 30px !important;
}

.profile-body {
	display: flex;
	align-items: center;
	flex-direction: column;
}
.grey {
background-color: #343a40!important;
}
.title{
	margin-left: 1em !important;
}
.main {
	min-height: 92vh;
}

.main {
	min-height: 92vh;
}

.main {
	min-height: 92vh;
}
.entries{
	border: 1px solid gray;
}

.redCount{
	color: red;
}
.main {
	min-height: 92vh;
}

.main {
  min-height: 92vh;
}
.table td {
  border: #000 solid 1px !important;
}
.table th {
  border: #000 solid 1px !important;
}

.main {
	min-height: 92vh;
}

.main {
	min-height: 92vh;
}

.main {
  min-height: 92vh;
}

.monthly-calender-root {
  width: -webkit-max-content;
  width: max-content;
  display: grid;
  grid-template-columns: repeat(7, 3rem);
  grid-template-rows: auto repeat(6, 3rem);
  grid-gap: 10px;
}

.monthly-calender-day {
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main {
	min-height: 92vh;
}

.main {
	min-height: 92vh;
}

